$grey: #8b8b8b;
$green : #f7d800;
$silver : #edeeee;
$green: #bad508;
$mint: #74dda5;

$imgPath: '../img/';

$font-size: 14px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px
);

$theme-colors: (
    "red": #cd563a,
    "silver": $silver
);

$box-shadow: 0 .5rem 1rem rgba($primary, .3);

$btn-border-radius: 23.5px;
$btn-border-radius-lg: 32.5px;
$btn-line-height: 34px;
$btn-padding-y-lg: 1.33rem;
$btn-padding-x-lg: 2.1rem;
$btn-padding-y-md: 1.15rem;
$btn-padding-x-md: 1.15rem;
$btn-font-size-md: 1.28rem;
$btn-line-height-md: 1.28rem;
$btn-border-radius-md: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

$modal-content-border-radius:0;

$border-color: $silver;

$service-hauling-bar-height: 120px;

$top-bar-height: 70px;
$top-bars-height: $top-bar-height*2;
$pagination-active-bg: $primary;