$current-theme-name: "yellow";
@import "src/scss/themes/_yellow.scss";


@import './config/fonts';
@import './config/variables';
@import './config/mixins';
@import "node_modules/bootstrap/scss/bootstrap";

@import './hc-offcanvas-nav/core';
@import './hc-offcanvas-nav/toggle';
@import './hc-offcanvas-nav/theme-default';

$pretty--color-primary: $primary;
@import 'node_modules/pretty-checkbox/src/pretty-checkbox.scss';

// main
html {
    font-size: $font-size;
}

body {
    font-family: 'HelveticaNeue', sans-serif;
    color: #000;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

a {
    text-decoration: none;
    color: #000;
    @include transition(.2s);
    &:hover {
        text-decoration: none;
        color: $red;
    }
}

img, iframe {
    max-width: 100%;
}

ul ul, ol ol {
    margin-bottom: 1rem;
}

.main {
    flex-grow: 1;
}

.h1, h1 {
    font-size: 2.2rem;
    text-transform: uppercase;
}

.h2, h2 {
    font-size: 1.8rem;
    text-transform: uppercase;
}

.h3, h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
}

.font-weight-500 {
    font-weight: 500;
}

// other
.border-right-grey {
    position: relative;
    &:after {
        content: "";
        border-right: 1px solid #525254;
        position: absolute;
        right: 15px;
        top: 4px;
        bottom: 6px;
    }
}

.btn {
    text-transform: uppercase;
    font-weight: 700;
}

.diagonal-bg {
    background: url($imgPath + 'grid-bg.png');
}

.leather {
    text-transform: uppercase;
    font-size: 0.79rem;
    height: $service-hauling-bar-height;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 1.5rem;
    &:before {
        @include before_img($imgPath + 'leather.png', 250px, 302px);
        position: absolute;
        z-index: 1;
    }
    &-text {
        z-index: 2;
    }
}

.yellow-inline-bg {
    background: $primary;
    box-shadow: 0 0 0 4px $primary;
}

.form-control {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #c9c9c9;
    padding-left: 0;
    outline: none;
    background: none;
    &:focus {
        box-shadow: none;
        border-color: $primary;
        background: none;
    }
    &.error {
        border-color: #f00;
    }
}

.dark-button-width-bg {
    &:before, &:after {
        content: "";
        position: absolute;
        width: 50vw;
        top: 0;
        bottom: 0;
        left: -50vw;
        background: #343a40;
    }
    &:after {
        left: inherit;
        right: -50vw;
    }
}

.dashed-link {
    border-bottom: 1px dashed #767777;
    &:hover {
        border-bottom-color: transparent;
    }
}

.barge-silver {
    background: #d3d4d4;
    width: 40px;
    height: 29px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 15px;
    margin-left: 10px;
}

.btn-no-radius {
    border-radius: 0;
}

.btn-md {
    @include button-size($btn-padding-y-md, $btn-padding-x-md, $btn-font-size-md, $btn-line-height-md, $btn-border-radius-md);
}
.contacts {
    &-fa-icon {
        color: $primary;
        font-size: 30px;
    }
    &-social {
        font-size: 22px;
        .fa-telegram {
            color: #0088cc;
        }
        .fa-viber {
            color: #665CAC;
        }
        .fa-whatsapp {
            color: #35b623;
        }
        .fab {
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

@for $i from 1 through 10 {
    .z-index-#{$i} {
        z-index: $i;
    }
}

.page-link {
    color: #555;
    text-decoration: none !important;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.bars-button {
    font-size: 25px;
    line-height: 25px;
    background: none;
    border: none;
    color: $primary;
    height: 35px;
    width: 35px;
    .fas {
        @include transition(.4s);
    }
    &:focus {
        outline: none;
    }
    &:not(.collapsed) .fas, &.toggle-open .fas {
        transform: rotate(-90deg);
    }
}

.form-group {
    position: relative;
    span.error {
        position: absolute;
        right: 0;
        top: 3pt;
        font-size: 12px;
        color: #cd563a;
    }
}

.modal-dialog {
    .form-control {
        border: 1px solid #dee2e6;
        padding: 10px;
        height: auto;
        &:focus {
            border-color: $primary;
        }
        &.error {
            border-color: #f00;
        }
    }
    .form-group span.error {
        right: 15px;
        top: 10px;
    }
}

.color {
    &-instagram {
        color: #8a3ab9;
        &:hover {
            color: darken(#8a3ab9, 15%);
        }
    }
    &-facebook {
        color: #3b5998;
        &:hover {
            color: darken(#3b5998, 15%);
        }
    }
    &-vk {
        color: #4c75a3;
        &:hover {
            color: darken(#4c75a3, 15%);
        }
    }
    &-youtube {
        color: #FF0000;
        &:hover {
            color: darken(#FF0000, 15%);
        }
    }
    &-twitter {
        color: #1DA1F2;
        &:hover {
            color: darken(#1DA1F2, 15%);
        }
    }
    &-telegram {
        color: #0088cc;
        &:hover {
            color: darken(#0088cc, 15%);
        }
    }
    &-viber {
        color: #665CAC;
        &:hover {
            color: darken(#665CAC, 15%);
        }
    }
    &-whatsapp {
        color: #25D366;
        &:hover {
            color: darken(#25D366, 15%);
        }
    }
    &-main {
        color: $primary !important;
    }
    &-yellow {
        color: $primary !important;
    }
}

.border-radius-10 {
    border-radius: 10px;
}

// slick
.slick-arrow {
    position: absolute;
    top: 20%;
    z-index: 1;
    cursor: pointer;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .8;
    @include transition(.2s);
    &-left {
        left: -1vw;
    }
    &-right {
        right: -1vw;
    }
    &:before {
        width: 32px;
        height: 32px;
        content: "";
        background: $primary;
        transform: rotateZ(45deg);
        z-index: 1;
        border-radius: 2px;
    }
    .fas {
        position: absolute;
        z-index: 1;
    }
    &:hover {
        opacity: 1;
    }
}
.slick-slide {
    &:focus, a {
        outline: none;
    }
}

.scroll-top {
    position: fixed;
    right: 35px;
    bottom: 16px;
    z-index: 1000;
    background: #fff;
    border: 2px solid $primary;
    border-radius: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    @include transition(.2s);
    &.show {
        opacity: .6;
        visibility: visible;
        &:hover, &:focus {
            background: $primary;
            color: #fff;
            opacity: .9;
        }
    }
}

.text-decoration-underline {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

// sections
.section {
    &-title {
        font-family: 'PT Sans Caption', sans-serif;
        text-transform: uppercase;
        letter-spacing: 8px;
        text-align: center;
        h1, h2, .h1, .h2 {
            font-size: 1.1rem;
            font-weight: 700;
            line-height: 1.8rem;
        }
        &-after a {
            color: #9ea1a1;
            font-size: .85rem;
        }
    }
    &-services-hauling {
        &:before {
            content: "";
            pointer-events: none;
            background: $silver;
            height: $service-hauling-bar-height;
            position: absolute;
            left: 0;
            right: 0;
        }
    }
    &-types {
        background: url($imgPath + 'types-bg.jpg') center no-repeat;
        background-size: contain;
    }
    &-info {
        width: 1282px;
        max-width: 100%;
        margin: 4rem auto 0;
        background: $silver;
        border-radius: 20px;
    }
    &-yellow-form {
        background-color: $primary;
        &:before {
            content: "";
            border: 10px solid transparent;
            border-bottom-color: $primary;
            position: absolute;
            top: -20px;
            left: calc(50% - 10px);
        }
    }
    &-before-after {
        background: linear-gradient(0deg, $silver 35%, transparent 35%);
    }
    &-reviews {
        .section-title {
            background: url($imgPath + 'reviews-bg.jpg') no-repeat center/contain;
            height: 118px;
            margin-top: 2rem;
            margin-bottom: 4rem;
            h2, .h2 {
                width: 388px;
                border-bottom: 1px solid #dcdddd;
                padding-bottom: 38px;
                margin-bottom: 34px;
            }
            &-en {
                background-image: url($imgPath + 'reviews-bg-en.jpg');
            }
            &-ua,&-uk {
                background-image: url($imgPath + 'reviews-bg-ua.jpg');
            }
            &-de {
                background-image: url($imgPath + 'reviews-bg-de.jpg');
            }
            &-bg {
                background-image: url($imgPath + 'reviews-bg-bg.jpg');
            }
            &-it {
                background-image: url($imgPath + 'reviews-bg-it.jpg');
            }
            &-ro {
                background-image: url($imgPath + 'reviews-bg-ro.jpg');
            }
            &-fr {
                background-image: url($imgPath + 'reviews-bg-fr.jpg');
            }
            &-lv {
                background-image: url($imgPath + 'reviews-bg-lv.jpg');
            }
            &-lt {
                background-image: url($imgPath + 'reviews-bg-lt.jpg');
            }
            &-gr {
                background-image: url($imgPath + 'reviews-bg-gr.jpg');
            }
            &-pl {
                background-image: url($imgPath + 'reviews-bg-pl.jpg');
            }
        }
    }
    &-faq {
        background-repeat: no-repeat;
        background-position: right;
        padding-bottom: 8rem;
        background-size: 600px;
        .faq-item-q {
            cursor: pointer;
        }
    }
    &-articles {
        .section-title {
            margin-top: 2.5rem;
            margin-bottom: 4rem;
        }
    }
    &-border-bottom {
        border-bottom: 2px solid #e5e5e5;
    }
    &-content-text a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &-silver {
        background-color: $silver;
    }
    &-instagram-title-fix {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
}

// icons
.icon {
    display: inline-flex;
    align-items: center;
    &:before {
        margin: 0 10px;
    }
    &-phone:before {
        @include before_img($imgPath + 'icon-phone.svg', 14px, 14px);
    }
    &-phone-dark:before {
        @include before_img($imgPath + 'icon-phone-dark.svg', 14px, 14px);
    }
    &-address:before {
        @include before_img($imgPath + 'icon-address.svg', 7px, 10px);
    }
    &-work-time:before {
        @include before_img($imgPath + 'icon-work-time.svg', 14px, 14px);
    }
    &-work-time-dark:before {
        @include before_img($imgPath + 'icon-work-time-dark.svg', 14px, 14px);
    }
    &-clock:before {
        @include before_img($imgPath + 'icon-clock.svg', 55px, 55px);
    }
    &-info:before {
        @include before_img($imgPath + 'icon-info.svg', 12px, 12px);
    }
    &-before-after:before {
        @include before_img($imgPath + 'icon-before-after.svg', 100px, 100px);
    }
    &-faq:before {
        @include before_img($imgPath + 'icon-faq.png', 32px, 32px);
    }
    &-map:before {
        @include before_img($imgPath + 'icon-map.svg', 182px, 251px);
    }
}

// controls
.controls {
    button {
        background: none;
        border: none;
        text-align: left;
    }
    .control, button {
        color: #fff;
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
        width: 47px;
        margin-right: 15px;
        cursor: pointer;
    }
    .control.active, .slick-active button {
        border-color: $primary;
        font-weight: 700;
        width: 141px;
        border-width: 2px;
        margin-bottom: -1px;
        margin-right: 30px;
    }
    &-dark {
        .control, button {
            color: #000;
            border-color: #595959;
        }
    }
    li, button {
        outline: none !important;
        @include transition(.5s);
    }
    
}

// header
.header {
    font-size: 13px;
    //background: url($imgPath + 'header-bg.jpg') #262626 no-repeat center/cover;
    background-color: #262626;
    a:not(.text-decoration-underline) {
        text-decoration: none;
    }
    &:before {
        @include before_img($imgPath + 'header-elipses.png', 996px, 444px);
        position: absolute;
        pointer-events: none;
        left: 50%;
        z-index: 4;
        transform: translateX(-50%);
    }
    &-top {
        &-bar {
            
            background: #040300;
            &:after {
                content: "";
                left: 0;
                right: 0;
                bottom: 0px;
                height: 1px;
                display: block;
                background: -moz-linear-gradient(left,  rgba(66,67,67,0) 0%, rgba(66,67,67,1) 50%, rgba(66,67,67,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(66,67,67,0) 0%,rgba(66,67,67,1) 50%,rgba(66,67,67,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(66,67,67,0) 0%,rgba(66,67,67,1) 50%,rgba(66,67,67,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00424343', endColorstr='#00424343',GradientType=1 ); /* IE6-9 */
            }
        }
        &-logo {
            min-height: $top-bar-height;
        }
    }
    &-middle-bar {
        height: $top-bar-height;
        background: rgb(12,13,13); /* Old browsers */
        background: -moz-linear-gradient(top,  rgba(12,13,13,1) 0%, rgba(35,38,38,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(12,13,13,1) 0%,rgba(35,38,38,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(12,13,13,1) 0%,rgba(35,38,38,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c0d0d', endColorstr='#232626',GradientType=0 ); /* IE6-9 */
        border-bottom: 1px solid #424748;
        color: $grey;
        a {
            color: $grey;
            &:hover {
                color: $primary;
            }
        }
    }
    &:not(.header-service):not(.min) {
        .header-content {
            min-height: 635px;
            padding-top: 65px;
            padding-bottom: 15px;
        }
    }
    &-slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &-text-1, &-text-1 h1 {
            font-size: 4.3rem;
            line-height: 4.2rem;
            color: $primary;
            font-weight: 700;
            text-transform: none;
        }
        &-text-2 {
            font-size: 2.85rem;
            line-height: 2.85rem;
            color: #fff;
            font-weight: 400;
        }
        .diagonal-bg {
            padding: 0 10px;
            margin: 0 -10px;
        }
        &-text-1, &-text-2 {
            position: relative;
            z-index: 2;
        }
        .slick-list, .slick-track {
            height: 100%;
        }
        &-dots {
            position: absolute;
            left: 0;
            right: 0;
            top: 65%;
        }
    }
    &-slide {
        &:nth-child(n+2) {
            display: none;
        }
        &:before {
            content: "";
            background-size: cover;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
    &-form {
        &-wrapper {
            background: #fff;
            padding: 25px 35px;
            border-radius: 25px;
            width: 286px;
            margin-right: 35px;
            margin-left: 22px;
            margin-top: -15px;
            position: relative;
            &:before {
                content: "\f4ad";
                font-family: Font Awesome\ 5 Free;
                width: 105px;
                height: 105px;
                position: absolute;
                right: -25px;
                top: -22px;
                background: $primary;
                border-radius: 25px;
                box-shadow: -5px 5px 15px -5px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 50px;
            }
        }
        &-title {
            font-size: 1.42rem;
            line-height: 1.42rem;
            font-weight: 700;
        }
        .form-control {
            border: 1px solid #ccc;
            background: #f3f3f3;
            padding: 10px;
            border-radius: 5px;
            height: calc(1.8em + .75rem + 2px);
            &:focus {
                border-color: $primary;
                background: #f5f5f5;
            }
            &.error {
                border-color: #f00;
            }
        }
        span.error {
            right: 15px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
        }
    }
    &-links {
        border-top: 3px solid #191919;
        &-title {
            color: $primary;
        }
        &-link, a {
            font-size: 1.29rem;
            color: #fff;
            border-bottom: 1px solid #484848;
            @include transition(color .2s);
            &:hover {
                color: $primary;
            }
        }
        &-item {
            display: flex;
            align-items: center;
            &:not(:last-child):after {
                content: "";
                width: 1px;
                height: 16px;
                background: $primary;
                margin: 0 18px;
            }
        }
        .border-right-grey:last-child:after {
            display: none;
        }
    }

    // nav
    .nav {
        font-size: 1.28rem;
        font-weight: 500;
        position: relative;
        padding: 2rem 0;
        &-wrapper {
            background-color: #232626;
        }
        &-item-has-childs:not(#id-29) {
            & > a, & > span {
                padding-right: 2rem !important;
                &:after {
                    content: "\f0dd";
                    font-family: 'Font Awesome 5 Free', sans-serif;
                    font-weight: 900;
                    position: absolute;
                    right: 1rem;
                    top: .8em;
                    font-size: .85rem;
                    line-height: .85rem;
                    color: $primary;
                }
            }
        }
        .lvl1 {
            margin: 0 1rem;
            position: relative;
            & > a, & > span {
                padding: .32rem 0;
                display: block;
                color: #fff;
                &:first-letter {
                    color: $primary;
                }
            }
            ul {
                @include transition(opacity .2s);
                opacity: 0;
                visibility: hidden;
                position: absolute;
                z-index: 1;
                padding: 10px 0;
                margin: 0;
                list-style: none;
                min-width: 220px;
                width: 100%;
            }
            &:hover > ul {
                opacity: 1;
                visibility: visible;
            }
            &.tid-10 {
                border: 1px solid #7f8587;
                border-radius: 18.5px;
                & > a, & > span {
                    padding: .32rem 2rem .32rem 1.5rem;
                }
            }
            
        }
        .lvl2 {
            border-bottom: 1px solid #424748;
            position: relative;
            a, span {
                color: #fff;
                background: rgba(0, 0, 0, 0.8);
                display: block;
                font-size: .8em;
                line-height: 1.2em;
                padding: .5em .8em;
            }
            // &.first, &.last {
            //     overflow: hidden;
            // }
            &.first {
                & > a, & > span {
                    border-radius: 10px 10px 0 0;
                }
                &:last-child > a, &:last-child > span {
                    border-radius: 10px;
                }
            }
            &.last {
                border-bottom: none;
                & > a, & > span {
                    border-radius: 0 0 10px 10px;
                }
            }
            ul {
                left: 100%;
                top: 0;
                padding: 0 0 0 5px;
                &:before {
                    content: "";
                    position: absolute;
                    border: 5px transparent solid;
                    left: -5px;
                    top: 10px;
                    display: block;
                    border-right-color: rgba(0, 0, 0, 0.8);
                }
            }
            &:hover > ul {
                opacity: 1;
                visibility: visible;
            }
        }
        .lvl3 {
            &.first {
                & > a, & > span {
                    border-radius: 10px 10px 0 0;
                }
                &:last-child > a, &:last-child > span {
                    border-radius: 10px;
                }
            }
            &.last {
                border-bottom: none;
                & > a, & > span {
                    border-radius: 0 0 10px 10px;
                }
            }
        }
        a {
            @include transition(color .2s);
            &:hover {
                color: $primary;
            }
        }
        .active span {
            color: $primary;
            cursor: default;
        }
    }
    &.min {
        background-image: none;
        &:before {
            display: none;
        }
        .header-content {
            min-height: inherit !important;
        }
    }
    &:not(.min) {
        .header-bottom {
            // background-image: url($imgPath + 'banners/ozeleneniye-mkhom.jpg');
            // background-position: center;
            // background-size: cover;
            background: #232626;
            background-size: cover;
            background-position: center;
        }
    }
    &-service {
        .diagonal-bg {
            color: #fff;
        }
        .service-price-old {
            margin-right: .5em;
            color: #999;
        }
    }
}

// breadcrumbs
.breadcrumb {
    align-items: center;
    justify-content: center;
    background: none;
    margin: 0;
    li {
        margin: 0 .5rem;
        font-size: .8rem;
    }
}

// reasons
.reason {
    &-img {
        height: 115px;
    }
    &-title {
        font-size: 1.28rem;
        line-height: 1.4rem;
        font-weight: 700;
    }
    
}


// card 
.classic-card {
    max-width: 320px;
    &-img {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        opacity: 0.9;
        background: $silver;
        min-height: 90px;
        @include transition(opacity .2s);
        &:before {
            content: "";
            pointer-events: none;
            width: 100%;
            height: 6px;
            left: 0;
            //top: 28px;
            top: 16%;
            position: absolute;
            background-color: $primary;
        }
    }
    &:hover &-img {
        opacity: 1;
    }
    &-min {
        .classic-card-img:before {
            height: 3px;
        }
    }
}

// services
.service {
    &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-title {
        font-size: .92rem;
        span {
            font-weight: 700;
        }
        &-large {
            font-size: 1rem;
        }
        a {
            text-decoration: none;
        }
    }
    &-price {
        width: 87px;
        height: 38px;
        line-height: 38px;
        background-color: $primary;
        text-align: center;
        border-radius: 19px;
        flex: 0 0 87px;
        margin-left: 15px;
        line-height: 14px;
        &-old {
            font-size: .9em;
            line-height: .9em;
            text-transform: initial;
            text-decoration: line-through;
            color: #555;
        }
    }
}
.service-hauling {
    &-img {
        height: $service-hauling-bar-height;
        &:before {
            content: '';
            background: url($imgPath + 'service-border.svg') no-repeat center;
            width: 100px;
            height: 100px;
            position: absolute;
        }
    }
    &-title {
        font-size: .86rem;
        line-height: 1rem;
        span {
            font-weight: 700;
            font-size: 1rem;
            text-transform: uppercase;
        }
    }
}

// type
.type {
    &-img {
        height: 220px;
        img {
            position: absolute;
            transform: translate(-50%, -20%);
        }
    }
}

// info


// who we
.who-we {
    &-title {
        &:after {
            //@include before_img($imgPath + 'quotation.png', 47px, 42px);
            content: '\f10d';
            font-family: Font Awesome\ 5 Free;
            font-size: 45px;
            line-height: 45px;
            color: $primary;
            margin-left: 40px;
        }
    }
    &-yellow {
        position: relative;
        background: $primary;
        width: 418px;
        border-radius: 25px;
        padding: 140px 40px 30px 40px;
        margin-top: 100px;
        margin-left: 37px;
        max-width: 100%;
        // &:before {
        //     @include before_img($imgPath + 'logo-min.png', 193px, 33px);
        //     position: absolute;
        //     top: -45px;
        //     right: 6px;
        // }
        &:after {
            content: "";
            width: 44px;
            height: 44px;
            background: #ffc66c;
            position: absolute;
            border-radius: 50%;
            left: 45px;
            bottom: -22px;
        }
        &-title {
            font-size: 6.57rem;
            line-height: 5rem;
            text-transform: uppercase;
            font-weight: 700;
            position: absolute;
            top: -35px;
            left: -40px;
            span {
                display: block;
                margin-left: 1.47em;
            }
            &:before {
                content: "";
                width: 44px;
                height: 44px;
                background: #bd4077;
                position: absolute;
                border-radius: 50%;
                right: -65px;
                bottom: -3px;
            }
        }
    }
    &-end {
        padding: 85px 35px 0 55px;
    }
}

// yellow form
.yellow-form {
    max-width: 906px;
    margin: 0 auto;
    &-title, .btn {
        font-size: 2.14rem;
        font-weight: 700;
    }
    .btn {
        line-height: 2rem;
    }
    .form-control {
        border-bottom-color: #000;
        &.error {
            border-color: #f00;
        }
    }
    &-wrapper {
        padding: 2rem 0 0 0;
    }
    .dark-button-width-bg {
        margin-top: 2.3rem;
    }
    &-text-2 {
        font-size: .85rem;
        color: #6d5f00;
        &:before {
            margin-right: 8px;
            flex: 0 0 12px;
        }
    }
}

// before-after
.before-after {
    &-title {
        height: 110px;
        background: url($imgPath + 'icon-before-after.svg') no-repeat center;
        font-size: 1.71rem;
        font-family: 'PT Sans Caption', sans-serif;
        font-weight: 700;
        margin-top: 10px;
        padding-top: 25px;
        padding-left: 35px;
        span {
            padding: 3px 6px 3px 16px;
            border-radius: 10px;
            display: inline-block;
        }
        &-black {
            background-color: #000;
            color: #fff;
            position: relative;
            z-index: 1;
            letter-spacing: 12px;
        }
        &-yellow {
            background-color: $primary;
            margin-left: -14px;
            letter-spacing: 10px;
            letter-spacing: 14px;
        }
    }
    &-nav {
        margin-top: 2rem;
        &-link {
            font-weight: normal;
            font-size: 1rem;
            border: 1px solid #ccc;
            border-radius: 19px;
            padding: 0 25px;
            height: 38px;
            display: flex;
            align-items: center;
            margin: 0 5px;
            &:hover, &.active {
                background-color: #e3e3e3;
                border-color: #e3e3e3;
                color: #000;
            }
        }
    }

}

// reviews 
.review {
    max-width: 314px;
    // margin: 0 auto;
    &-car {
        font-family: 'PT Sans Caption', sans-serif;
        text-transform: uppercase;
        letter-spacing: 4px;
    }
    &-img {
        width: 100%;
        height: 198px;
        &-driver {
            position: absolute;
            width: 90px;
            height: 90px;
            display: block;
            border-radius: 100px;
            overflow: hidden;
            border: 5px solid #fff;
            bottom: -20px;
            right: -15px;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        img {
            border-radius: 10px;
        }
    }
    &-title {
        line-height: 1.3rem;
        margin-bottom: 1.2rem;
        &:after {
            //@include before_img($imgPath + 'quotation.png', 47px, 42px);
            content: '\f10d';
            font-family: Font Awesome\ 5 Free;
            margin-left: 25px;
            margin-right: 10px;
            font-size: 45px;
            line-height: 45px;
            color: $primary;
        }
    }
    &-description {
        font-size: .85rem;
    }
}

// faq 
.faq {
    &-title {
        font-size: 6.57rem;
        line-height: 6.57rem;
        color: #333;
        span {
            color: #000;
            position: relative;
            z-index: 1;
        }
        &.icon-faq:before {
            position: absolute;
            left: calc(50% - 16px);
            top: calc(50% - 16px);
            z-index: 2;
        }
    }
    &-subtitle {
        font-size: .85rem;
    }
    &-item {
        position: relative;
        margin-right: 1.25rem;
        padding: 0 35px 0 5px;
        min-height: 140px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &-q, &-a {
            position: relative;
            z-index: 2;
        }
        &-q {
            font-size: 1.28rem;
            line-height: 1.5rem;
            font-weight: 700;
        }
        &-a {
            display: none;
        }
        &:before {
            content: "";
            position: absolute;
            left: -25px;
            top: -1px;
            background: #eeefef;
            right: 5px;
            bottom: -40px;
            z-index: 1;
            border-radius: 25px;
        }
        &:before {
            opacity: 0;
            visibility: hidden;
            @include transition(.2s);
        }
        &.active {
            position: absolute;
            z-index: 3;
            right: 15px;
            left: 15px;
            top: 0;
            padding-top: 2rem;
            .faq-item-a {
                display: block;
            }
            &:before, &:after {
                opacity: 1;
                visibility: visible;
            }
            .faq-item-q {
                cursor: inherit;
            }
        }
        //&:not(.last) {
        & {
            border-bottom: 1px solid $primary;
        }
    }
}

// articles
.article {
    &-title {
        a {
            text-decoration: underline;
            line-height: 1.3rem;
            display: inline-block;
        }
    }
    &-img {
        img {
            width: 100%;
        }
    }
    &-description {
        font-size: .85rem;
    }
    &-date {
        font-size: .76rem;
        font-weight: 700;
        color: #7f7f7f;
    }
    &-page {
        &-author {
            &, a {
                color: #777;
            }
            a:hover {
                color: $primary;
            }
            img {
                border-radius: 100px;
                margin-right: 10px;
            }
        }
        &-date {
            color: #777;
            font-size: .9rem;
        }
    }
}
.article-min .article-title a {
    font-size: .9rem;
}


// footer
.footer {

    &-top {
        background-color: $primary;
    }
    &-bottom {
        background-color: #000000;
        min-height: 73px;
        color: #fff;
        &-item {
            position: relative;
            z-index: 1;
        }
        &-links {
            font-size: 13px;
            color: #888;
            a {
                color: #888;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    &-logo {
        &:before {
            left: -100vw;
        }
        &:after {
            right: -100vw;
        }
    }
    &-address {
        font-size: 1.28rem;
        font-weight: 700;
    }
    &-list-item {
        width: 20%;
    }
    .icon-map:before {
        position: absolute;
        top: -34px;
        left: calc(50% - 91px);
    }
    &-menu {
        font-size: .8rem;
        border-top: 1px solid #f3f3f3;
        padding-top: 10px;
    }
    .contacts-social {
        font-size: 40px;
    }
    .section-title {
        letter-spacing: 9px;
    }
}

// classic list

%classic-list {
    list-style: none;
    padding-left: 2em;
    li:before {
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-weight: 900;
        margin-right: 1em;
        color: $primary;
        font-size: 0.8em;
        margin-top: 0.1em;
        float: left;
    }
}
.classic-list {
    @extend %classic-list;
    &-checklist li:before {
        content: '\f00c';
    }
    &-arrows li:before {
        content: '\f04b';
    }
    &-outside {
        li {
            position: relative;
            margin: 10px 0;
            &:before {
                position: absolute;
                left: -20px;
                top: 2pt;
            }
        }

    }
}

.section-content {
    ul:not(.pagination) {
        @extend %classic-list;
        li:before {
            content: '\f04b';
        }
    }
    ol{
        counter-reset: niceCounter;
        padding-left: 1.8em;
        li{
            list-style: none;
        }
        li:before {
            counter-increment: niceCounter;
            content:counter(niceCounter);
            color: white;
            background: $primary;
            display: inline-block;
            text-align: center;
            line-height: 15px;
            width: 15px;
            height: 15px;
            margin: 1px 1em 1px 0;
            font-size: 0.9em;
        }
    }
    
}

.gmaps iframe {
    width: 100%;
    height: 400px;
}

.jGrowl-notification .jGrowl-close {
    margin-top: -2px;
}

/* Overwriting some Pretty-Checkbox styles to allow for word wrapping */
.pretty {
    white-space: normal;
    width: 100%;
}

.pretty .state label 
{
    text-indent: 0;
    padding-left: 1.5rem;
}

.pretty .state label:after,
.pretty .state label:before,
.pretty.p-icon .state .icon 
{
    top: 0;
}

.hidden-privacy-policy.error + .pretty {
    color: #f00;
}

.embed-youtube {
    background-color: #000;
    margin-bottom: 30px;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
}
.embed-youtube img {
    width: 100%;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
}
.embed-youtube .embed-youtube-play {
    width: 68px;
    height: 48px;
    background-color: #333;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}
.embed-youtube .embed-youtube-play:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}
.embed-youtube img,
.embed-youtube .embed-youtube-play {
    cursor: pointer;
}
.embed-youtube img,
.embed-youtube iframe,
.embed-youtube .embed-youtube-play,
.embed-youtube .embed-youtube-play:before {
    position: absolute;
}
.embed-youtube .embed-youtube-play,
.embed-youtube .embed-youtube-play:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
}
.embed-youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.embed-youtube .embed-youtube-play:hover {
    background-color: #f00;
}

.embed-youtube-wrapper {
    max-width: 100%;
}

@media screen and (min-width: 1260px) { 
    .header-slider {
        &-text-1, &-text-2 {
            padding-right: 355px;
        }
    }
    .review-wrapper {
        min-width: 400px;
    }
}

@media screen and (min-width: 992px) { 
    .instagram-banner {
        width: 100%;
    }
}

@media screen and (max-width: 1259px) {
    body {
        min-width: 320px;
    }
    img {
        max-width: 100%;
    }
    .section-content {
        img {
            height: auto !important;
        }
    }
}

@media screen and (max-width: 1259px) and (min-width: 992px) {
    .slick-arrow {
        top: 16%;
    }
    .section {
        &-faq {
            background-size: 40%;
        }
    }
    .header {
        .nav {
            font-size: 1.2rem;
            .lvl1 {
                margin: 0 1rem;
                & > a, & > span {
                    padding: .3rem 1.2rem;
                }
            }
        }
        &-links {
            &-link {
                font-size: 1.2rem;
            }
        }
        &-middle-bar {
            height: auto;
        }
    }
    .who-we-end {
        padding: 14rem 0 0 3.3rem;
    }
    .review-img {
        height: 150px;
    }
    .type-img {
        height: 165px;
        img {
            transform: translate(-50%,-5%);
        }
    }
    .review-wrapper {
        min-width: 320px;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .section {
        &-info {
            border-radius: 0;
        }
        &-who-we {
            background-size: contain;
            background-repeat: no-repeat;
        }
        &-intro {
            .container {
                &:before, &:after {
                    display: none;
                }
            }
        }
        &-faq {
            background-size: 40%;
        }
    }
    .header {
        &:before {
            display: none;
        }
        &-slider {
            // &-text-1, &-text-2 {
            //     max-width: 50%;
            // }
            &-text-1 {
                font-size: 3rem;
                line-height: 3rem;
            }
            &-text-2 {
                font-size: 2rem;
                line-height: 2rem;
            }
            
        }
        &-form-wrapper {
            margin-right: 0;
            &:before {
                right: -20px;
            }
        }
        &-links {
            &-link {
                font-size: 1rem;
            }
            &-item:after {
                margin: 0 15px;
            }
        }
        &-slider-dots {
            top: 60%;
        }
        &-content {
            min-height: 630px;
        }
        .nav .lvl1 ul {
            width: 145px;
            min-width: inherit;
        }
        &-middle-bar {
            height: inherit;
        }
        &-service {
            .header-slider {
                padding-bottom: 50px;
                &-text-2 {
                    text-align: center;
                }
                .diagonal-bg {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                }
            }
            .header-form-wrapper {
                margin: 0;
            }
        }
    }
    .reason {
        &-img, &-title, &-text {
            padding-left: 0;
        }
        &-img {
            width: 80px;
            height: 80px;
            margin: 0 auto;
        }
    }
    .type-img {
        height: 130px;
        img {
            transform: translate(-50%,-10%);
        }
    }
    .who-we {
        &-yellow {
            margin: 55px 0 75px 0;
        }
        &-end {
            padding: 0;
        }
    }
    .review-wrapper {
        min-width: 360px;
    }
}

//@media screen and (max-width: 575px) {
@media screen and (max-width: 767px) {
    .h1, h1 {
        font-size: 1.6rem;
    }
    .h2, h2 {
        font-size: 1.4rem;
    }
    .h3, h3 {
        font-size: 1.2rem;
    }
    .section {
        &-intro {
            .container {
                &:before, &:after {
                    display: none;
                }
            }
        }
        &-services-hauling:before {
            display: none;
        }
        &-who-we {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom center;
        }
        &-info {
            border-radius: 0;
            margin-top: 0;
        }
        &-faq {
            background-image: none !important;
            padding-bottom: 3rem;
        }
        &-reviews .section-title {
            margin: 0;
        }
        &-title {
            letter-spacing: 5px;
        }
        &-types {
            background: none;
        }
        &-instagram-title-fix {
            .fs-22 {
                font-size: 1.4rem;
            }
        }
    }
    .header {
        &-top-logo {
            img {
                height: auto;
            }
        }
        &:before {
            display: none;
        }
        &-middle-bar {
            height: inherit;
        }
        &-slider {
            &-text-1, &-text-1 h1 {
                font-size: 3rem;
                line-height: 3.5rem;
                h1 {
                    line-height: 2.7rem;
                }
            }
            &-text-2 {
                font-size: 2rem;
                line-height: 2rem;
                
            }
            .diagonal-bg {
                font-size: 2.5rem;
            }
            &-dots {
                position: absolute;
                top: inherit;
                bottom: 3vh;
            }

        }
        &-slide .container, &-slide-empty .container {
            padding-top: 220px;
        }
        &-service .header-slider {
            padding-bottom: 50px;
        }
        &:not(.header-service):not(.min) .header-content {
            min-height: 680px;
            // min-height: calc(100vh - #{$top-bars-height});
        }
        &:not(.header-service):not(.min) .header-content.without-form {
            min-height: 290px;
        }
        &-form-wrapper {
            margin: 0 0 5px 0;
            max-width: 100%;
            background: rgba(255, 255, 255, 0.95);
            &:before {
                right: 0;
                top: -35px;
            }
        }
        .nav {
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.9);
            font-weight: normal;
            padding: 0;
            .lvl1 {
                width: 100%;
                margin: 0;
                ul {
                    display: none;
                }
                a, span {
                    padding: .32rem 2rem .32rem 1.5rem;
                    &:after {
                        display: none;
                    }
                }
            }
            a:after {
                display: none;
            }
            &-item-has-childs {
                border: none !important;
            }
            & > ul {
                padding: 10px 0;
            }
        }
        
    }
    .diagonal-bg {
        background-color: rgba(0, 0, 0, 0.6);
    }
    .service-hauling {
        &-img:before {
            background: url($imgPath + 'service-border-white.svg') no-repeat 50%;
        }
    }
    .reason {
        &-img {
            height: 70px;
            width: 70px;
            margin: 0 auto;
            padding: 0;
        }
        &-title {
            font-size: 1.2rem;
            padding-left: 0;
        }
        &-text {
            padding-left: 0;
            line-height: 1.2rem;
        }
    }
    .who-we {
        &-yellow {
            margin-left: 0;
            margin-top: 4rem;
            padding: 2rem;
            width: auto;
            margin-bottom: 100px;
            &-title {
                font-size: 5rem;
                line-height: 4rem;
                position: static;
                margin-bottom: 1rem;
                span {
                    margin-left: 20vw;
                }
                &:before {
                    display: none;
                }
            }
            &:before {
                right: 0;
                left: 0;
                width: 100%;
            }
        }
        &-middle {
            margin-left: 0;
        }
        &-end {
            padding: 0;
        }
    }
    .footer {
        &-top {
            min-height: inherit;
            padding-top: 3rem;
        }
        &-bottom {
            height: auto;
        }
        .icon-map:before {
            width: 66px;
            height: 66px;
            left: calc(50% - 33px);
        }
    }
    .slick-arrow {
        &-left {
            left: 0;
        }
        &-right {
            right: 0;
        }
    }
    .controls {
        button, .slick-active button {
            font-size: 0;
            width: 15px;
            height: 15px;
            background: #ccc;
            margin: 0 15px;
            border-radius: 100px;
            padding: 6px;
        }
        .slick-active button {
            background: $primary;
        }
    }
    .faq {
        &-title {
            font-size: 4rem;
            line-height: 4rem;
        }
        &-item {
            min-height: inherit;
            padding: 1rem 0;
            margin-right: 0;
            &-q {
                padding-right: 45px;
                font-size: 1.15rem;
            }
            &.active {
                position: static;
                padding: 1rem 0;
                &:before {
                    display: none;
                }
            }
        }
    }
    .before-after {
        &-title {
            padding-left: 0;
            &-black {
                letter-spacing: 5px;
            }
            &-yellow {
                letter-spacing: 10px;
            }
        }
    }
    .type-img {
        height: auto;
        img {
            position: static;
            transform: inherit;
            margin-bottom: -30px;
            margin-top: -40px;
        }
    }
    .instagram-banner {
        height: 200px;
        max-width: inherit;
    }
    .review-wrapper {
        min-width: 320px;
    }
}

@media screen and (max-width: 575px) {
    .who-we-middle .btn {
        font-size: 1rem;
    }
    .header-slider {
        &-text-3 {
            font-size: .8em;
            display: block;
        }
        &-text-4 {
            font-size: .6em;
            display: block;
        }
        .diagonal-bg {
            padding: 1rem 0;
            width: 100%;
        }
    }
}