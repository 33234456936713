@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/HelveticaNeue/HelveticaNeue.eot');
    src: url('../fonts/HelveticaNeue/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
         url('../fonts/HelveticaNeue/HelveticaNeue.svg#HelveticaNeue') format('svg'),
         url('../fonts/HelveticaNeue/HelveticaNeue.ttf') format('truetype'),
         url('../fonts/HelveticaNeue/HelveticaNeue.woff') format('woff'),
         url('../fonts/HelveticaNeue/HelveticaNeue.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/HelveticaNeue/HelveticaNeue-Medium.eot');
    src: url('../fonts/HelveticaNeue/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Medium.ttf') format('truetype'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Medium.woff') format('woff'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/HelveticaNeue/HelveticaNeue-Bold.eot');
    src: url('../fonts/HelveticaNeue/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Bold.ttf') format('truetype'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Bold.woff') format('woff'),
         url('../fonts/HelveticaNeue/HelveticaNeue-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans Caption';
    src: url('../fonts/PTSans/PT Sans Caption.eot');
    src: url('../fonts/PTSans/PT Sans Caption.eot?#iefix') format('embedded-opentype'),
         url('../fonts/PTSans/PTSans-Caption.svg#PTSans-Caption') format('svg'),
         url('../fonts/PTSans/PTSans-Caption.ttf') format('truetype'),
         url('../fonts/PTSans/PT Sans Caption.woff') format('woff'),
         url('../fonts/PTSans/PT Sans Caption.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans Caption';
    src: url('../fonts/PTSans/PT Sans Caption Bold Regular.eot');
    src: url('../fonts/PTSans/PT Sans Caption Bold Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/PTSans/PTSans-CaptionBold.svg#PTSans-CaptionBold') format('svg'),
         url('../fonts/PTSans/PTSans-CaptionBold.ttf') format('truetype'),
         url('../fonts/PTSans/PT Sans Caption Bold Regular.woff') format('woff'),
         url('../fonts/PTSans/PT Sans Caption Bold Regular.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}