@mixin before_img($i, $w: auto, $h: auto) {
    content: "";
    background-image: url($i);
    background-repeat: no-repeat;
    background-position: center;
    display: block;

    width: $w;
    height: $h;
}

@mixin fs($size) {
    $remValue: ($size/$font-size);
    font-size: #{$remValue}rem;
}

$fsizes: 12, 15, 16, 18, 20, 22, 24, 28;
@each $fsize in $fsizes {
  .fs-#{$fsize} {
    font-size: #{$fsize}px;
  }
  .lh-#{$fsize} {
    line-height: #{$fsize}px;
  }
}